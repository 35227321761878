import React, { useEffect, useState } from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';
import Blog from '../../assets/json/blog.json';


const BlogSidebar = (props) => {
    const [hasNav, setNav] = useState(false);

    useEffect(() => {
        setNav(document !== null);
    }, []);

    return (
        <>
            { hasNav && ( 
                <div className="widget-area" id="secondary">
                    <div className="widget widget_startp_posts_thumb">
                        <h3 className="widget-title">ULTIMI ARTICOLI</h3>
                        {
                            Blog.articles.filter(a => a.id != props.id).map(article => {
                                return <article onClick={()=> window.location.href= "/" + article.page} className="item" style={{cursor:"pointer"}}>
                                <Link className="thumb">
                                    <span className="fullimage cover" role="img" style={{backgroundImage: `url("${require('../../assets/images' + article.img).default}")`}}></span>
                                </Link>

                                <div className="info">
                                    <time>{article.data}</time>
                                    <h4 className="title usmall">
                                        <Link>
                                            {article.title}
                                        </Link>
                                    </h4>
                                </div>
            
                                <div className="clear"></div>
                            </article>

                            })
                        }
                    </div>
                </div>
            )}
        </>
    )
}

export default BlogSidebar;  