import React from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";

import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 1,
    title: "Perché è così importante proteggere i tuoi sistemi informatici?",
    desc:
        "Con la pandemia da Covid-19 che ci accompagna da più di due anni, il contesto informatico e tecnologico ha acquisito maggiore rilevanza nella vita di tutti noi. Ma perché senti sempre parlare di Cyber security? ",
    img: "/blog-image/sicurezza1.jpg",
    imgs: [
        "/blog-image/react_native.png",
        "/blog-image/app.png",
        "/blog-image/app2.png",
    ],
    page: "blog/cyber_security",
    data: "01 Ago 2022",
    author: "Staff",
};

const BlogDetails = () => (
    <Layout>
        <SEO
            title={articleData.title}
            meta={[
                {
                    name: `description`,
                    content:
                        "Con la pandemia da Covid-19 che ci accompagna da più di due anni, il contesto informatico e tecnologico ha acquisito e rilevanza nella vita di tutti noi.",
                },
                {
                    property: `og:title`,
                    content: articleData.title + "Appius, software house",
                },
            ]}
        />

        <Navbar />

        <PageBanner pageTitle={articleData.title} />

        <div className="blog-details-area ptb-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img
                                    src={
                                        require("../../assets/images" +
                                            articleData.img).default
                                    }
                                    alt="image"
                                />
                            </div>

                            <div className="article-content">
                                <div className="entry-meta">
                                    <ul>
                                        <li>
                                            <Icon.Clock /> {articleData.data}
                                        </li>
                                        <li>
                                            <Icon.User />{" "}
                                            <Link to="#">
                                                {articleData.author}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <p>
                                    Con la pandemia da Covid-19 che ci
                                    accompagna da più di due anni, il contesto
                                    informatico e tecnologico ha acquisito
                                    maggiore rilevanza nella vita di tutti noi.
                                    Ma perché senti sempre parlare di Cyber
                                    security?{" "}
                                </p>

                                <p>
                                    L’ampliamento delle tecnologie e dei suoi
                                    utilizzatori ha comportato un incremento
                                    degli attacchi alla nostra sicurezza
                                    informatica. La Cyber security, ovvero
                                    l’insieme delle persone, dei processi e
                                    degli strumenti che combinati tra loro
                                    aiutano a proteggerci dagli attacchi
                                    digitali, è quindi un argomento a cui devi
                                    prestare crescente attenzione. Che tu sia
                                    un’azienda o un semplice cittadino poco
                                    importa, gli attacchi hacker non fanno
                                    distinzioni. Dati recenti dimostrano che già
                                    nel primo semestre del 2022 il numero di
                                    attacchi informatici registrati è stato
                                    superiore all’intero 2021. Con l’avvento
                                    della pandemia e il ripensamento al fattore
                                    Smart Working nelle aziende, i luoghi dove è
                                    possibile lavorare sono aumentati,
                                    determinando una crescita degli ambienti da
                                    proteggere. Questo trend sta categorizzando,
                                    sempre di più, la Cyber security come
                                    investimento strategico.
                                </p>

                                <img
                                    src={
                                        require("../../assets/images/blog-image/sicurezza-informatica.jpg")
                                            .default
                                    }
                                    style={{ marginBottom: 40, marginTop: 30 }}
                                    alt="Sicurezza informatica"
                                />
                                <br />

                                <p>
                                    Il processo di digitalizzazione è a buon
                                    punto ma la sicurezza informatica può
                                    risentirne perché, nel giro di poco tempo,
                                    aziende di piccole dimensioni si sono dovute
                                    adattare a nuovi bisogni e contesti
                                    digitali. Questa fase di transizione ha
                                    portato a galla carenze dal lato della
                                    sicurezza, influenzate dalle risorse
                                    disponibili nelle imprese.
                                </p>

                                <p>
                                    Lavoratori e cittadini risultano ancora poco
                                    attenti e informati, ampliando così la
                                    platea di bersagli per i cyber-criminali e
                                    le certificazioni sulla sicurezza
                                    informatica tradizionali stanno diventando
                                    obsolete.
                                    <br />
                                    Vediamo ora perché la Cyber security gioca
                                    un ruolo fondamentale nella scelta del
                                    software per il tuo business.
                                    <br />
                                    Tutte le figure che prendono parte processo
                                    di sviluppo del software sanno che la
                                    caratteristica che non può mancare è la
                                    protezione dei dati personali. Il software è
                                    veicolo di traffico di informazioni
                                    sensibili. Le misure di sicurezza riguardano
                                    la tecnologia in quanto tale e l’intera
                                    organizzazione. Alcuni limiti di
                                    quest’ultima potrebbero portare attività
                                    standard, come ad esempio la crittografia su
                                    database, ad essere poco efficaci nel
                                    garantire una operatività ottimale in caso
                                    di attacco. La transizione verso l’Industria
                                    4.0 richiede un adeguamento severo che le
                                    Aziende e i professionisti non possono più
                                    trascurare. Garantire software efficienti e
                                    sicuri è un servizio che deve essere
                                    garantito per clienti e per la legge!
                                    <br />
                                    Contattare una Software House seria e
                                    competente per lo sviluppo del software è
                                    un’ottima base di partenza; non solo perché
                                    garantisce da subito solide basi per la
                                    sicurezza, ma anche perché apportare
                                    modifiche in seguito può risultare poco
                                    vantaggioso e funzionale. Una progettazione
                                    sicura e testata è oggi necessaria per
                                    rispondere alle nuove esigenze dei mercati.
                                    La valutazione del rischio accompagna tutte
                                    le fasi di sviluppo del software: è
                                    necessario correlare alle richieste del
                                    cliente e alla valutazione dei rischi
                                    applicazioni in grado di far fronte ad
                                    eventuali lacune. La successiva scrittura ed
                                    analisi del codice sorgente del software
                                    comportano dei test per verificare possibili
                                    limiti. L’intero processo, una volta
                                    concluso, necessita di un ulteriore esame
                                    per assicurarsi che tutti gli elementi diano
                                    i risultati attesi.
                                </p>

                                <p>
                                    La transizione digitale è quindi in pieno
                                    corso, con sempre più tutele appositamente
                                    pensate per le necessità di tutti gli
                                    utenti: innovazione e sicurezza devono
                                    essere pensate come elementi indivisibili.
                                    In Appius Software House ci impegniamo ogni
                                    giorno a fornire soluzioni tecnologiche
                                    adeguate, innovative e sicure. La
                                    realizzazione di un software progettato da
                                    professionisti comporta una riduzione dei
                                    rischi. Rischi che se non adeguatamente
                                    calcolati nella fase iniziale comporteranno
                                    un costo elevato e ripercussioni severe in
                                    futuro, compromettendo l’intera affidabilità
                                    dell’Azienda verso i propri clienti. Il
                                    nostro processo produttivo è
                                    all’avanguardia, gli strumenti che ti
                                    forniamo sicuri e testati. Mettiamo la
                                    nostra esperienza al servizio delle tue
                                    necessità, ti forniamo applicazioni e
                                    supporto per tutelare la privacy dei tuoi
                                    clienti e collaboratori da attacchi
                                    informatici. Richiedi subito una consulenza
                                    gratuita, saremo felici di condividere con
                                    te i nostri servizi e le nostra risorse in
                                    tema di sicurezza.
                                    <br />
                                    <br />
                                    Contattaci ora e scopri come proteggere il
                                    tuo business.
                                </p>
                                <br />
                                <Link to="/contact" className="btn btn-primary">
                                    Contattaci
                                </Link>
                            </div>

                            <div
                                style={{ marginTop: 30 }}
                                className="startp-post-navigation"
                            >
                                {Blog.articles
                                    .filter((a) => a.id != articleData.id)
                                    .slice(0, 2)
                                    .map((article) => {
                                        return (
                                            <div className="prev-link-wrapper">
                                                <div className="info-prev-link-wrapper">
                                                    <Link
                                                        to={"/" + article.page}
                                                    >
                                                        <span className="image-prev">
                                                            <img
                                                                src={
                                                                    require("../../assets/images" +
                                                                        article.img)
                                                                        .default
                                                                }
                                                                alt="image"
                                                            />
                                                            <span className="post-nav-title">
                                                                VAI
                                                            </span>
                                                        </span>

                                                        <span className="prev-link-info-wrapper">
                                                            <span className="prev-title">
                                                                {article.title}
                                                            </span>
                                                            <span className="meta-wrapper">
                                                                <span className="date-post">
                                                                    {
                                                                        article.data
                                                                    }
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <BlogSidebar id={articleData.id} />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
);

export default BlogDetails;
